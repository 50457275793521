<template>
  <div class="container mx-auto">
    <div class="flex flex-wrap justify-center w-full bg-white">
      <div class="text-black w-12x4 md:w-2/4">
        <div class="flex flex-wrap justify-center">
          <div class="px-4">
            <div class="px-4 mt-12 text-left">
              <h2 class="subtitulo-pagina">Muestras Individuales</h2>
              <ul class="lista-premios">
                <li>
                  1990 - Banco de Boston Sucursal Belgrano. Diciembre 1990.
                </li>
                <li>1994 - Fundación del Banco de Boston.</li>
                <li>1995 - Museo Municipal de Azul Pcia.Buenos Aires</li>
                <li>
                  1995 - Por antecedentes: exposición 1ra. quincena de Noviembre
                  1995 en la Fundación del Banco del Noroeste Argentino.
                </li>
                <li>
                  1996 - Arte BA 96. Galería Boggiano. Centro Cultural Recoleta
                </li>
                <li>1996 - Palais de Glace. Julio de 1996 Buenos Aires</li>
                <li>
                  1996 - Museo Municipal de Bellas Artes de Tandil Agosto 1996.
                  Pcia. de Buenos Aires.
                </li>
                <li>
                  1997 - Salon Nacional de artes plásticas.Seleccion por jurado
                </li>
                <li>
                  1998 - Casa Rosada. Buenos Aires Argentina. “Futuro
                  imperfecto” A Beneficio de Unicef.
                </li>
                <li>
                  1999 - Museo Municipal de Bellas Artes de Tandil. Pcia. de
                  Buenos Aires.
                </li>
                <li>1999 - Arte contemporáneo Cuba II Salon Ernesto Valor</li>
                <li>
                  2002 - Ministerio de Cultura y Educación Sala “Eduardo Di
                  Nardo” Santa Rosa – La Pampa
                </li>
                <li>
                  2003 - Museo Municipal de artes plásticas Faustino Bonadeo.
                  Rauch Pcia Buenos Aires.
                </li>
                <li>2003 - Casa de la Cultura de Tarija-Bolivia.</li>
                <li>2004 - Galeria Nucleo de Arte Mirta Furmanski.</li>
                <li>
                  2005 - Museo Historico Municipal Miguez Mercedes Pcia.Buenos
                  Aires
                </li>
                <li>2008 - Centro Cultural Borges Buenos Aires</li>
                <li>
                  2009 - Museo de Arte Contemporaneo Americano – La Plata –
                  Pcia. de Buenos Aires
                </li>
                <li>2009 - Centro Cultural Puente Blanco San Luis</li>
                <li>2010 - Museo “La Estacion” Puerto Madryn Pcia.Chubut</li>
                <li>2010 - Galeria de Arte Ahrus Rafaela Pcia. Sante Fe</li>
                <li>
                  2010 - Arte Clasica 10 Feria de Arte moderno y contemporáneo
                  Buenos Aires
                </li>
              </ul>

              <h2 class="subtitulo-pagina">
                Ingreso por jurado a Muestras Colectivas
              </h2>
              <ul class="lista-premios">
                <li>1990 - Salón Homenaje Raúl Soldi. Noviembre 1990.</li>
                <li>
                  1990 - Salón del Pequeño Formato CEARCO. Noviembre 1990.
                </li>
                <li>
                  1996 - Arte Contemporáneo Argentino -Uruguay- Auspicio
                  Ministerio de Relaciones Exteriores y Culto de la República
                  Argentina y Dirección de Turismo del Uruguay
                </li>
                <li>
                  2004 - Muestra colectiva Fundacion Museo de Arte Rómulo Raggio
                </li>
                <li>2004 - Galeria Nucleo de Arte</li>
                <li>
                  2005 - Muestra colectiva en Viena-Austria Galeria Wort und
                  bild
                </li>
                <li>
                  2009 - Soltanto Azzurro Centro Cultural Borges Buenos Aires
                </li>
                <li>
                  2010 - Arte Clasica 10 Feria de Arte moderno y contemporáneo
                  Buenos Aires
                </li>
              </ul>

              <h2 class="subtitulo-pagina">Publicaciones</h2>
              <ul class="lista-premios">
                <li>2001 Arte Argentino Actual. Critico Rosa Faccaro</li>
                <li>
                  2003 Enciclopedia Ibero Americana de Artistas Queensbourough
                  Comunity College – The City University of New York
                </li>
                <li>2003 Creadores del Arte Argentino</li>
                <li>2004 El gran Libro de Arte Argentino.</li>
                <li>
                  2005 El Arte Argentino y sus protagonistas. Critico Cesar
                  Magrini
                </li>
                <li>
                  2006 El Arte Argentino hacia el mundo. Critico Osvaldo
                  Mastromauro.
                </li>
                <li>
                  2007 El Libro de oro del Arte Argentino. Critico Daniel Perez.
                </li>
                <li>2008 Panorama del Arte Argentino .Critico Daniel Perez.</li>
                <li>
                  2009 El Arte Argentino del Bicentenario. Critico Mara de
                  Giovanni
                </li>
                <li>
                  2011 Los elegidos del Arte Argentino Critico Maria Tamala
                  Revyehis
                </li>
                <li>
                  2012 Destacados exponentes del Arte Argentino Critico Rosa
                  Faccaro
                </li>
                <li>
                  2013 La actualidad en Arte Argentino Critico Rosa Faccaro
                </li>
                <li>
                  2016 Colección Arte Argentino talento y creación Critico Rosa
                  Faccaro
                </li>
                <li>
                  2017 Arte Argentino 20th aniversario Critico Fabiana Barreda
                </li>
                <li>
                  2018 Grandes talento Arte Argentino de hoy Critico Ana Piasek
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="text-black w-12x4 md:w-2/4">
        <article class="p-5 m-1 mt-10 text-left">
          <h2 class="subtitulo-pagina">
            Ingreso por Jurado a Muestras Colectivas
          </h2>
          <ul class="list-disc">
            <li>
              1991 - Sala Auditorium Edificio Casino de Mar del Plata. Febrero
              1991.
            </li>
            <li>1991 - OMMA en el Arte. Junio 1991.</li>
            <li>
              1994 - 1ra. Bienal Bs.As. de pintura abstracta.Bs.As. Agosto 1994.
            </li>
            <li>
              1994 - 2do. Salón de Pintura Circulo Médico de Vte. López Pcia. de
              Bs.As. Octubre 94
            </li>
            <li>
              1994 - 2do. Salón Integración de Pintura. Fundación Integración.
              Octubre 1994.
            </li>
            <li>
              1994 - XVII Salón Anual de Artes Plásticas. Municipalidad de
              V.Constitución.Nov 1994.
            </li>
            <li>
              1994 - XVI Salón Nacional de Pintura "Fernan Félix de
              Amador".Noviembre 1994
            </li>
            <li>
              1994 - Consejo Federal de Inversiones. Centro Universal de las
              Artes Auspiciado por el gobierno de San Juan. Noviembre 1994.
            </li>
            <li>1994 - Salón Bellas Artes Ramón Gómez Cornet 1994.</li>
            <li>1995 - Salón Nueva Figuración 1995. CEARCO. Febrero 1995.</li>
            <li>
              1995 - Museo Municipal de Bellas Artes de Tandil. Salón Arte Sacro
              1995.
            </li>
            <li>1995 - Muestra Once por Once. Laboratorios Schering</li>
            <li>
              1995 - Muestra de artistas Argentinos Galería Núcleo de Arte.
              Abril 1995
            </li>
            <li>1995 - 60° Salón de Otoño. SAAP. Mayo 1995.</li>
            <li>
              1995 - Salón Inaugural de pintura '95.Centro Cultural Catedral.
              Mayo 1995.
            </li>
            <li>
              1995 - Salón Homenaje a Ernesto "Che" Guevara. Centro Cultural
              Recoleta. Mayo 1995.
            </li>
            <li>1995 - Salón Arte en San Telmo. Galería Núcleo de Arte</li>
            <li>1995 - Avon Salón 25 aniversario Agosto 1995</li>
            <li>
              1995 - Salón Encuentro en el Arte. Fundación Andreani Agosto 1995
            </li>
            <li>
              1995 - Salón Actualidad en el Arte '95. (Espacio Giesso). 1995
            </li>
            <li>
              1995 - 3er Premio. Museo Roca. II Salón "Nuevas Imágenes 1995
            </li>
            <li>
              1995 - 3er.Salón de Pintura Circulo Médico de Vte.Lopez. Octubre
              1995
            </li>
            <li>
              1995 - Salón Nacional de Arte contemporáneo. Casa de la Cultura de
              San Isidro
            </li>
            <li>
              1995 - 12 Salón Azul Nacional de Artes Plásticas Municipalidad del
              partido de Azul de la Pcia. de Buenos Aires.
            </li>
            <li>1995 - Salón del Pequeño Formato SAAP</li>
            <li>
              1996 - Sala de Exposiciones de Pta. del Este "José G.Artigas".
              Ministerio de Relaciones Exteriores y Culto de la República
              Argentina
            </li>
            <li>
              1996 - IV Salón Nacional de Pintura Región Bonaerense. Concurso
              AVON. Agosto 1996
            </li>
            <li>
              1996 - Arte Infinito. 1er. Salón de Pintura. Imagen Satelital.
            </li>
            <li>1997 - Salón municipal Claudio L. Sempere.</li>
            <li>
              1997 - XXXVI Salón Nacional de Artes Plásticas. Ministerio de
              Cultura de la Nación.
            </li>
            <li>1998 - Salón de Pintura Avon. La mujer en el Arte.</li>
            <li>1998 - Palacio de las Artes Belgrano R. La Casella.</li>
            <li>1998 - Sociedad Argentina de Artistas Plásticos.</li>
            <li>1999 - Salón Nacional de Plástica.</li>
            <li>1999 - Arte Contemporáneo Argentino en Cuba II.</li>
            <li>2000 - Arte Contemporáneo Argentino en Cuba III.</li>
            <li>2001 - Galería Núcleo de Arte.</li>
            <li>2001 - St. Paul’s College. Segunda Edición.</li>
            <li>2002 - Salón Crisis Argentina. EDEA.</li>
            <li>2002 - Sala “Eduardo Dii Nardo” Santa Rosa. La Pampa</li>
            <li>
              2002 - Museo Municipal “Faustino J.Bonadeo” Rauch. Buenos Aires.
            </li>
            <li>2003 - Fundacion Puffin .Teaneck, New Jersey EEUU</li>
            <li>2004 - Galeria Nucleo de Arte</li>
            <li>
              2005 - Salon Regional de pintura 2005 Fundacion Avon Tandil Buenos
              Aires
            </li>
            <li>2005 - Wort Undbild. Galeria Austria</li>
            <li>2009 - Centro Cultural Borges “Soltanto Azurro”</li>
            <li>2009 -Galeria Nucleo de Arte</li>
          </ul>

          <h2 class="subtitulo-pagina">Premios obtenidos</h2>
          <ul class="list-disc">
            <li>
              Premio Selección Destacada, categoría Pintura. 1ra. Bienal
              Bs.As.Bs.As.1994.
            </li>
            <li>Premio Segunda Mención. 1ra. Bienal Bs.As. Bs.As. 1994.</li>
            <li>
              Segundo Premio. Categoría Plásticos. Circulo Médico Vte.López.
              1994.
            </li>
            <li>1ra. Mención. Centro Universal de las Artes. 1994.</li>
            <li>
              Primer Premio, categoría Pintura. Galería de Arte Tokio. 1994.
            </li>
            <li>
              Primer Premio, categoría Pintura. Museo Bellas Artes Ramón
              G.Cornet.
            </li>
            <li>Mención Especial Consejo Federal de Inversiones 1994</li>
            <li>
              Primer Premio Museo de Bellas Artes de Santiago del Estero 1995
            </li>
            <li>Tercera Mención Salón de San Telmo. Pintura Abstracta</li>
            <li>
              Premio Mencion 3er.Salon Artes Plasticas Centro Cultural Yukio
              Mishima Abril 1995
            </li>
            <li>Trercer premio Museo Roca Salon Nueva Figuración.</li>
            <li>2da. Mención Salón Municipal Claudio Sempere. 1997.</li>
            <li>
              2da. Mención Centro de Exposiciones Casa de la Moneda. 1997.
            </li>
            <li>
              Mención de Honor Salón Nacional de Plástica. Gran Premio Raúl
              Soldi. 1999.
            </li>
            <li>2do. Premio La Casa del Arte. 2001.</li>
            <li>Mencion Salon de la Mujer en el Arte 2006,</li>
          </ul>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cv"
};
</script>
